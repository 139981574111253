// extracted by mini-css-extract-plugin
export var box = "qiufen-module--box--11be4";
export var circle = "qiufen-module--circle--5928b";
export var content = "qiufen-module--content--90afc";
export var des = "qiufen-module--des--ad535";
export var describe = "qiufen-module--describe--ca903";
export var divider = "qiufen-module--divider--ddc93";
export var download = "qiufen-module--download--465fa";
export var downloadText = "qiufen-module--downloadText--12e05";
export var downloadType = "qiufen-module--downloadType--62707";
export var downloadWrap = "qiufen-module--downloadWrap--21cb0";
export var guide = "qiufen-module--guide--f78e0";
export var head = "qiufen-module--head--1ebe8";
export var headText = "qiufen-module--headText--65ca3";
export var headTitle = "qiufen-module--headTitle--64784";
export var hoverBox = "qiufen-module--hoverBox--599a5";
export var intro = "qiufen-module--intro--303c0";
export var item = "qiufen-module--item--537f2";
export var itemText = "qiufen-module--itemText--aba0f";
export var linkIcon = "qiufen-module--linkIcon--b67f0";
export var littleT = "qiufen-module--littleT--76b04";
export var mainContent = "qiufen-module--mainContent--45843";
export var newText = "qiufen-module--newText--cb659";
export var outer = "qiufen-module--outer--5410a";
export var source = "qiufen-module--source--6f4f2";
export var titleWrap = "qiufen-module--titleWrap--ba378";
export var useDes = "qiufen-module--useDes--b4cba";
export var useItem = "qiufen-module--useItem--44cdc";
export var useTitle = "qiufen-module--useTitle--6c0c7";
export var version = "qiufen-module--version--661a2";
export var wrap = "qiufen-module--wrap--ccac4";
export var wrapItems = "qiufen-module--wrapItems--a63e2";