import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "../styles/qiufen.module.less"
import { message } from "antd"
import { ExportOutlined } from "@ant-design/icons"
import Link from "../utils/link"
import Seo from "../components/seo"
import step1GIF from "../images/step-1.gif"
import step2GIF from "../images/step-2.gif"
import step3GIF from "../images/step-3.gif"
import step4GIF from "../images/step-4.gif"
import step5GIF from "../images/step-5.gif"

const DownloadPage = ({ location }) => {
  // 下载
  const download = url => {
    try {
      let elemIF = document.createElement("iframe")
      elemIF.src = url
      elemIF.style.display = "none"
      document.body.appendChild(elemIF)
      setTimeout(() => {
        document.body.removeChild(elemIF)
      }, 2000)
    } catch (e) {
      message.error("下载异常！")
    }
  }

  return (
    <div className={styles.outer}>
      <Seo
        title="秋分"
        description="发现GraphQL的奇妙之处，从秋分开始，解锁编码的新境界"
        image="../images/qiufen_logo@3x.png"
      />
      <div className={styles.mainContent}>
        <div className={styles.head} style={{ backgroundColor: "transparent" }}>
          <div className={styles.headTitle}>
            <StaticImage
              layout="constrained"
              outputPixelDensities={[0.25, 0.5, 1, 2, 3]}
              src="../images/qiufen_logo@3x.png"
              width={36}
              height={36}
              alt="Profile picture"
              placeholder="blurred"
            />
            <span className={styles.headText}>秋分</span>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.titleWrap}>
            <StaticImage
              layout="constrained"
              outputPixelDensities={[0.25, 0.5, 1, 2, 3]}
              src="../images/qiufen_text@3x.png"
              width={98}
              alt="Profile picture"
              placeholder="none"
            />
            <div className={styles.describe}>
              与秋分同行，让每一次调试都变得有趣
              <br />
              东半球最好用的 GraphQL 调试利器
            </div>
          </div>
          <div className={styles.downloadWrap}>
            <div
              className={styles.download}
              onClick={() => {
                download(
                  "https://file-007.obs.cn-southwest-2.myhuaweicloud.com/software/public/release/qiufen-win.msi.zip"
                )
              }}
            >
              <StaticImage
                layout="constrained"
                outputPixelDensities={[0.25, 0.5, 1, 2, 3]}
                src="../images/download_win@x3.png"
                width={60}
                height={60}
                alt="download-win-picture"
                placeholder="blurred"
              />
              <div className={styles.downloadText}>下载 Windows 客户端</div>
            </div>
            <div className={styles.download}>
              <div className={styles.box}>
                <StaticImage
                  layout="constrained"
                  outputPixelDensities={[0.25, 0.5, 1, 2, 3]}
                  src="../images/download_mac@x3.png"
                  width={60}
                  height={60}
                  alt="download-mac-picture"
                  placeholder="blurred"
                />
                <div className={styles.downloadText}>下载 Mac 客户端</div>
              </div>
              <div className={styles.hoverBox}>
                <div
                  className={styles.downloadType}
                  onClick={() => {
                    download(
                      "https://file-007.obs.cn-southwest-2.myhuaweicloud.com/software/public/release/qiufen-x86.app.tar.gz"
                    )
                  }}
                >
                  Intel chip
                </div>
                <div
                  className={styles.downloadType}
                  onClick={() => {
                    download(
                      "https://file-007.obs.cn-southwest-2.myhuaweicloud.com/software/public/release/qiufen-arm64.app.tar.gz"
                    )
                  }}
                >
                  Apple chip
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.intro}>
        <div className={styles.wrapItems}>
          <div className={styles.item}>
            <StaticImage
              layout="constrained"
              src="https://gw.alipayobjects.com/zos/bmw-prod/881dc458-f20b-407b-947a-95104b5ec82b/k79dm8ih_w144_h144.png"
              width={60}
              height={60}
              alt="intro-api-picture"
              placeholder="blurred"
            />
            <p className={styles.itemText}>API文档</p>
          </div>
          <div className={styles.item}>
            <StaticImage
              layout="constrained"
              src="https://gw.alipayobjects.com/zos/bmw-prod/d60657df-0822-4631-9d7c-e7a869c2f21c/k79dmz3q_w126_h126.png"
              width={60}
              height={60}
              quality={95}
              alt="intro-mock-picture"
              placeholder="blurred"
            />
            <p className={styles.itemText}>Mock服务</p>
          </div>
          <div className={styles.item}>
            <StaticImage
              layout="constrained"
              src="https://gw.alipayobjects.com/zos/bmw-prod/d1ee0c6f-5aed-4a45-a507-339a4bfe076c/k7bjsocq_w144_h144.png"
              width={60}
              height={60}
              alt="intro-online-picture"
              placeholder="blurred"
            />
            <p className={styles.itemText}>在线调试</p>
          </div>
        </div>
        <div className={styles.version}>
          <p className={styles.des}>
            秋分 1.0.5 发布
            <p className={styles.newText}>
              <span>New</span>
            </p>
          </p>
          <p>2024-03-08</p>
        </div>
        <div className={styles.guide}> 使用指南</div>
        <div className={styles.useItem}>
          <div className={styles.wrap}>
            <div className={styles.useTitle}>查询</div>
            <div className={styles.circle} />
            <p className={styles.useDes}>
              <strong>添加 URL</strong> 地址， <strong>点击 Publish</strong>{" "}
              按钮，查看对应环境的接口信息，点击
              <strong> Request</strong> 按钮，发起 <strong>GraphQL</strong>{" "}
              请求，查看响应数据
            </p>
          </div>
          <div>
            <img src={step1GIF} style={{ width: 860 }} />
          </div>
        </div>
        <div className={styles.useItem}>
          <div>
            <img src={step2GIF} style={{ width: 860 }} />
          </div>
          <div className={styles.wrap}>
            <div className={styles.useTitle}>配置</div>
            <div className={styles.circle} />
            <div className={styles.littleT}>配置多环境</div>
            <p className={styles.useDes}>
              右上角选择<strong>管理环境</strong>，在配置页
              <strong>新增环境</strong>，实现多环境配置，配置完成后可在右上角
              <strong>直接切换环境</strong>
            </p>
          </div>
        </div>
        <div className={styles.useItem}>
          <div className={styles.wrap}>
            <div className={styles.useTitle}>配置</div>
            <div className={styles.circle} />
            <div className={styles.littleT}>添加环境变量</div>
            <p className={styles.useDes}>
              右上角选择<strong>管理环境</strong>，在配置页中
              <strong>添加环境变量</strong>
            </p>
          </div>
          <div>
            <img src={step3GIF} style={{ width: 860 }} />
          </div>
        </div>
        <div className={styles.useItem}>
          <div>
            <img src={step4GIF} style={{ width: 860 }} />
          </div>
          <div className={styles.wrap}>
            <div className={styles.useTitle}>配置</div>
            <div className={styles.circle} />
            <div className={styles.littleT}>配置多参数</div>
            <p className={styles.useDes}>
              选择需要调试的接口，在下方为请求头添加多个
              <strong>环境参数</strong>
            </p>
          </div>
        </div>
        <div className={styles.useItem}>
          <div className={styles.wrap}>
            <div className={styles.useTitle}>配置</div>
            <div className={styles.circle} />
            <div className={styles.littleT}>配置全局参数</div>
            <p className={styles.useDes}>
              右侧点击<strong>设置图标</strong>，添加
              <strong>全局参数</strong>，此后将在所有<strong> Headers </strong>
              中查看到
            </p>
          </div>
          <div>
            <img src={step5GIF} style={{ width: 860 }} />
          </div>
        </div>

        <div className={styles.divider} />
        <div className={styles.source}>
          相关资源：
          <Link
            to="https://techblog.hjgpscm.com/"
            itemProp="url"
            target="_blank"
          >
            维护团队
            <ExportOutlined className={styles.linkIcon} />
          </Link>
          、
          <Link
            to="https://hjfruit.github.io/xiaoshu-doc/"
            itemProp="url"
            target="_blank"
          >
            小暑
            <ExportOutlined className={styles.linkIcon} />
          </Link>
          、
          <Link
            to="https://hjfruit.github.io/bailu-doc/"
            itemProp="url"
            target="_blank"
          >
            白露
            <ExportOutlined className={styles.linkIcon} />
          </Link>
          、
          <Link
            to="https://hjfruit.github.io/hanlu-doc/"
            itemProp="url"
            target="_blank"
          >
            寒露
            <ExportOutlined className={styles.linkIcon} />
          </Link>
          、
          <Link
            to="https://hjfruit.github.io/icon-doc/"
            itemProp="url"
            target="_blank"
          >
            SVG 图标库
            <ExportOutlined className={styles.linkIcon} />
          </Link>
          、
          <Link
            to="https://hjfruit.github.io/laba-doc/"
            itemProp="url"
            target="_blank"
          >
            腊八
            <ExportOutlined className={styles.linkIcon} />
          </Link>
          、
          <Link
            to="https://hjfruit.github.io/utils/"
            itemProp="url"
            target="_blank"
          >
            工具集
            <ExportOutlined className={styles.linkIcon} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default DownloadPage
